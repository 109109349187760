const routes = require('../../../../../../../js/fos_js_routes.json');
import Routing from '../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);


export class PageManager {
	constructor() {
    $('a[data-page-view-details="true"]').each(function(){
      $(this).on('click', function(){
        PageManager.viewPageDetails($(this).data('page-id'));
      });
    });
	}

	static viewPageDetails(id) {
    var route = Routing.generate('eobs_cms_admin_page_details', {'id': id});
    $.ajax({
     url : route,
     type : 'GET',
     success : function(response, status){
      $('#page-details').html(response);
      App.initialize();
     }
    });
  }
}
