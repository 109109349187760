import React from 'react';
import {render} from 'react-dom';
import {ContentExplorer} from './components/cms/ContentExplorer.jsx';
import {MediaExplorer} from './components/cms/MediaExplorer.jsx';
import {MenuBuilder} from './components/cms/MenuBuilder.jsx';
import {PageBuilder} from './components/cms/PageBuilder.jsx';
import {PageManager} from './components/cms/PageManager.jsx';
import {SeoChecker} from './components/cms/SeoChecker.jsx';
import ConfirmationModal from './components/ConfirmationModal';

// CMS

new ContentExplorer();
new MediaExplorer();
new PageBuilder();
new MenuBuilder();
new PageManager();
new SeoChecker();

/**
 * Rendering component React : ConfirmationModal
 */
const confirmationModals = Array.prototype.slice.call(document.getElementsByClassName('component-confirmation-modal'));

confirmationModals.forEach((element) => {
    render(
        <ConfirmationModal
            action={element.dataset.action}
            className={element.dataset.class}
            message={element.dataset.message}
            modalTitle={element.dataset.modalTitle}
            text={element.dataset.text}
            title={element.dataset.title}
        />,
        element,
    );
});
