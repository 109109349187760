const routes = require('../../../../../../../js/fos_js_routes.json');
import Routing from '../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);

const dragula = require('dragula');

export class PageBuilder {
	constructor(){
		this.initPageBuilderDragAndDrop();
	}

	initPageBuilderDragAndDrop() {
      var dragContainers = [];
      var selection = document.querySelectorAll('.drag-container');
      selection.forEach(function(item) {
        dragContainers.push(document.getElementById(item.id));
      });

      var drake = dragula(dragContainers, {
        moves: function (el, container, handle) {
          return handle.classList.contains('handle');
        },
      });

      drake.on('drop', function(el, target, source, sibling) {

        var sourceZone = $(source).data('zone');
        var targetZone = $(target).data('zone');
        var sourcePageConfigIds = [];
        var targetPageConfigIds = [];

        $(source).find('.page-config').each(function(){
          sourcePageConfigIds.push($(this).data('page-config-id'));
        });


        $(target).find('.page-config').each(function(){
          targetPageConfigIds.push($(this).data('page-config-id'));
        });

        var route = Routing.generate('eobs_cms_admin_page_config_move');
        $.ajax({
         url : route,
         type : 'POST',
         data: {
          sourceZone: sourceZone,
          targetZone: targetZone,
          sourcePageConfigIds: sourcePageConfigIds,
          targetPageConfigIds: targetPageConfigIds,
         },
         success : function(response, status){

         }
        });

      });
    }
}
