export class SeoChecker {
	constructor() {

    if ($('#page_route').length > 0
      && $('#page_seoTitle').length > 0
      && $('#page_seoMetaDescription').length > 0
    ) {
      SeoChecker.changeAlias($('#page_route').val());

      SeoChecker.changeTitle($('#page_seoTitle').val());
      SeoChecker.checkCorrectLgTitle($('#page_seoTitle').val(), 'title');

      SeoChecker.changeDescription($('#page_seoMetaDescription').val());
      SeoChecker.checkCorrectLgDescription($('#page_seoMetaDescription').val(), 'description');

      $('#page_seoTitle').on('keyup', function(){
        SeoChecker.changeTitle($(this).val());
        SeoChecker.checkCorrectLgTitle($(this).val(), 'title');
      });

      $('#page_seoMetaDescription').on('keyup', function(){
        SeoChecker.changeDescription($(this).val());
        SeoChecker.checkCorrectLgDescription($(this).val(), 'description');
      });

      $('#page_route').on('keyup', function(){
        SeoChecker.changeAlias($(this).val());
      });
    }
	}

  static changeTitle(title)
  {
    const maxCaracTitle = 70;
    title = title.toString();

    if (title.length > maxCaracTitle) {
      serp = title.substring(0,maxCaracTitle);
      title = serp + ' <b>...</b>';
    }

    if(title == '') {
      title = 'Titre';
    }

    $("#google_preview_title").html(title);
  }

  static changeDescription(description)
  {
    const maxCaracDescription = 160;
    let desc = '';
    description = description.toString();

    if (description.length > maxCaracDescription) {
      desc = description.substring(0,maxCaracDescription);
      description = desc + ' <b>...</b>';
    }

    if (description == '') {
      description = 'Description';
    }

    $("#google_preview_description").html(description);
  }

  static changeAlias(alias)
  {
    let url_page = window.location.protocol + '//' + window.location.hostname + '/';
    if (alias == '') {
      alias = '<i>alias</i>';
    }
    $("#google_preview_url").html(url_page+alias);
  }

  static checkCorrectLgTitle(value_balise, id_balise)
  {
    const lgTitleMin = 50;
    const lgTitleMax = 60;

    if (value_balise.length < lgTitleMin) {
      document.getElementById(id_balise+'_court').style.display="inline";
      document.getElementById(id_balise+'_correct').style.display="none";
      document.getElementById(id_balise+'_long').style.display="none";
    } else if (value_balise.length < lgTitleMax) {
      document.getElementById(id_balise+'_court').style.display="none";
      document.getElementById(id_balise+'_correct').style.display="inline";
      document.getElementById(id_balise+'_long').style.display="none";
    } else {
      document.getElementById(id_balise+'_court').style.display="none";
      document.getElementById(id_balise+'_correct').style.display="none";
      document.getElementById(id_balise+'_long').style.display="inline";
    }
  }

  static checkCorrectLgDescription(value_balise, id_balise)
  {
    const lgDescriptionMin = 140;
    const lgDescriptionMax = 160;

    if (value_balise.length < lgDescriptionMin) {
      document.getElementById(id_balise+'_court').style.display="inline";
      document.getElementById(id_balise+'_correct').style.display="none";
      document.getElementById(id_balise+'_long').style.display="none";
    } else if (value_balise.length < lgDescriptionMax) {
      document.getElementById(id_balise+'_court').style.display="none";
      document.getElementById(id_balise+'_correct').style.display="inline";
      document.getElementById(id_balise+'_long').style.display="none";
    } else {
      document.getElementById(id_balise+'_court').style.display="none";
      document.getElementById(id_balise+'_correct').style.display="none";
      document.getElementById(id_balise+'_long').style.display="inline";
    }
  }
}
